import { isNotEmptyArray } from './arrays.helper';

export function isValidTag(tag) {
  return tag && tag.title && tag.color && typeof tag.title === 'string' && typeof tag.color === 'string';
}

export function findMainTag(tags) {
  if (isNotEmptyArray(tags)) {
    return tags.sort((a, b) => b.weight - a.weight)[0];
  }
}
