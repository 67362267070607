import React from 'react';
import './sticky-search-wrapper.scss';

const StickySearchWrapper = ({children}) => {
  return (
    <div className={`row justify-content-center sticky-row mb-5 mb-lg-7`}>
      <div className="col col-12 col-lg-8 pt-2">
        {children}
      </div>
    </div>
  );
}

export default StickySearchWrapper;
