import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { productTypes } from '../../../constants/product-types.const';
import { isPositiveIntegerOrZero } from '../../../helpers/number.helper';
import './products-section.scss';
import { getAll, getOne } from '../../../helpers/query-params.helper';
import { isNotEmptyArray } from '../../../helpers/arrays.helper';
import hasWindow from '../../../constants/has-window.const';
import { useLocation } from '@reach/router';
import ProductsList from '../../Common/product-list/ProductsList';

const ProductsSection = ({
  products,
  productPath,
  viewAllPath,
  productType,
  title,
  isPreviewMode = false,
  className = '',
  isFilter = false
}) => {

  const [viewAllLink, setViewAllLink] = useState(() => getViewAllPath());

  if (hasWindow) {
    const deps = isPreviewMode ? [] : [useLocation()];

    useEffect(() => {
      if (hasWindow) {
        setViewAllLink(getViewAllPath());
      }
    }, deps)
  }

  function isProducts() {
    return products && isNotEmptyArray(products.data);
  }

  function isTotalCount() {
    return products && products.meta && isPositiveIntegerOrZero(products.meta.totalCount);
  }
  
  function getViewAllPath() {
    let productPageUrl = `${viewAllPath}/?page=1`;
    const query = getOne('query');
    const tagsParamString = getTagParamsString();

    if (query) {
      productPageUrl = `${productPageUrl}&query=${query}`
    }

    if (tagsParamString) {
      productPageUrl =`${productPageUrl}&${tagsParamString}`;
    }

    if (productType === productTypes.lesson) {
      const instrumentsParamString = getParamsStringByKey('instruments');
      productPageUrl = instrumentsParamString ? `${productPageUrl}&${instrumentsParamString}` : productPageUrl;
    }

    return productPageUrl;
  }

  function getTagParamsString() {
    const tagsKey = 'tags';
    const tags = getAll(tagsKey) || [];
    let tagsParamString = '';

    tags.forEach(tag => {
      const [packageTag, lessonTag] = tag.split(':');
      let tagString = '';

      if (productType === productTypes.lesson) {
        tagString = `${tagsKey}=${lessonTag}`
      }

      if (productType === productTypes.package) {
        tagString = `${tagsKey}=${packageTag}`
      }

      tagsParamString = tagString ? `${tagsParamString}&${tagString}` : tagsParamString;
    });

    return tagsParamString;
  }

  function getParamsStringByKey(key = '') {
    const paramValues = getAll(key) || [];
    let paramsString = '';

    paramValues.forEach(tag => {
      const param = `${key}=${tag}`
      paramsString = param ? `${paramsString}&${param}` : paramsString;
    });

    return paramsString;
  }

  return isProducts() && (
    <section className={`${className} products-section mb-2`}>
      <div className={`d-flex justify-content-between align-items-center`}>
        <h2 className={`h3 text-uppercase products-section-title mb-2 mb-md-3`}>
          {title}
          {isTotalCount() && (<span className={`ml-2 counter`}>({products.meta.totalCount})</span>)}
        </h2>
        <Link to={viewAllLink} className={`medium`}>View all</Link>
      </div>
      <hr className={`divider`}/>
      <div className={`product-list-wrapper mt-3 mt-md-5`}>
        <ProductsList
          products={products.data}
          productType={productType}
          productPath={productPath}
          isFilter={isFilter}
          colClassName={'col col-6 col-sm-4 col-md-3 col-xl-5th'}
        />
      </div>
    </section>
  );
}

ProductsSection.propTypes = {
  products: PropTypes.object,
  title: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
  productPath: PropTypes.string.isRequired,
  viewAllPath: PropTypes.string.isRequired,
  isPreviewMode: PropTypes.bool,
  isFilter: PropTypes.bool,
  className: PropTypes.string,
};

export default ProductsSection;

