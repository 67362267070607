import React, { useState, useEffect } from 'react';
import './search.scss'
import PropTypes from 'prop-types';
import Select from 'react-select';
import { navigate } from 'gatsby';
import { getQueryParams, removeAll } from '../../../helpers/query-params.helper';
import hasWindow from '../../../constants/has-window.const';
import { searchOptions } from '../../../constants/search-options.const';

const Search = ({
  inputQuery = '',
  defaultSearchOption = searchOptions[0],
  hasError = false,
  isPreviewMode = false,
  className = '',
  anchor = ''
}) => {
  const maxLength = 255;

  const [ query, setQuery ] = useState(() => (inputQuery || ''));
  const [ searchOption, setSearchOption ] = useState(defaultSearchOption);
  const [ isFocused, setIsFocused ] = useState(false);

  useEffect(() => {
    if (inputQuery !== query) {
      setQuery(inputQuery);
    }
  }, [inputQuery]);

  function onSearchChange(event) {
    setQuery(event.target.value);
  }

  function clearSearchInput() {
    setQuery('');
    search('');
  }

  function hasSearchQuery() {
    return query && query.length > 0;
  }

  function onSearchFocus() {
    setIsFocused(true);
  }

  function onSearchBlur() {
    setIsFocused(false);
  }

  function handleSubmit(event) {
    event.preventDefault();
    search(query, true);
  }

  function search(value = '', useAnchor = false) {
    if (isPreviewMode || !hasWindow) {
      return;
    }

    const params = getUpdatedParams(value);

    let path = `${searchOption.value}?${params.toString()}`;

    if (anchor && useAnchor) {
      path = `${path}#${anchor}`;
    }

    return navigate(path);
  }

  function getUpdatedParams(query = '') {
    let params = getQueryParams();

    if (isSearchOptionChanged()) {
      params = removeAll(params);
    }

    params = updateQueryParamInParams(params, query);
    params = updatePageParamInParams(params);
    return params;
  }

  function updateQueryParamInParams(params, query = '') {
    if (query) {
      params.set('query', query);
    } else {
      params.delete('query');
    }

    return params;
  }

  function updatePageParamInParams(params) {
    if (isPagination(searchOption.value)) {
      params.set('page', String(1));
    } else {
      params.delete('page');
    }

    return params;
  }

  function isSearchOptionChanged() {
    return searchOption.value !== defaultSearchOption.value;
  }

  function isPagination(path) {
    return path !== '/specials';
  }

  function getErrorClass() {
    return hasError ? 'border-danger' : ''
  }

  function getFocusedClass() {
    return isFocused ? 'is-focused' : ''
  }

  return (
    <div className={`${className} custom-search-block`}>
      <form onSubmit={handleSubmit} className={`d-flex`} role="search">
        <div
          onFocus={onSearchFocus}
          onBlur={onSearchBlur}
          className={`search-wrapper flex-grow-1 py-1 py-sm-2 d-flex ${getFocusedClass()} ${getErrorClass()}`
        }>
          <div className={`select-search-entity`}>
            <Select
              value={searchOption}
              onChange={setSearchOption}
              options={searchOptions}
              className={`select-search-container`}
              classNamePrefix={`select-search`}
              isSearchable={false}
            />
          </div>
          <hr className={`divider vertical dark`}/>
          <div className={`ml-2 mb-0 flex-grow-1 search-control`}>
            <span className="uil uil-search search-icon search-input-icon d-none d-sm-inline"/>
            <input
              type="search"
              value={query}
              onChange={onSearchChange}
              className={`search-input w-100 h-100 pl-1`}
              placeholder={`Search`}
              maxLength={maxLength}
            />
            { hasSearchQuery() && (<span className="uil uil-times clear-icon search-input-icon" onClick={clearSearchInput}/>) }
          </div>
        </div>
        <button type='submit' title={`search`} className={`ml-1 btn-tg btn-tg-sale btn-tg-search`}>
          <span className={`d-none d-md-block`}>Search</span>
          <span className={`uil uil-search search-icon d-md-none`}/>
        </button>
      </form>
      {
        hasError && (<span className={`hint text-danger`}>Invalid search query. Search query cannot exceed {maxLength} characters.</span>)
      }

    </div>
  );
};

Search.propTypes = {
  inputQuery: PropTypes.string,
  defaultSearchOption: PropTypes.object,
  className: PropTypes.string,
  anchor: PropTypes.string,
  isPreviewMode: PropTypes.bool,
  hasError: PropTypes.bool,
};

export default Search;
