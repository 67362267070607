import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Search from '../Common/Search/Search';
import QueryParamsHelper from '../../helpers/query-params.helper';
import hasWindow from '../../constants/has-window.const';
import { productsLimit } from '../../constants/pagination-limits.const';
import axiosService  from '../../services/axios.service';
import ProductsSection from './ProductsSection/ProductsSection';
import { productTypes } from '../../constants/product-types.const';
import TopOptionsList from './TopOptions/TopOptionsList';
import { transformToAttribute } from '../../helpers/attributes.helper';
import { useLocation } from '@reach/router';
import { handleBadRequestError } from '../../helpers/error-handler.helper';
import { productAttribute } from '../../constants/product-attribute.const';
import { searchOptions } from '../../constants/search-options.const';
import { isNotEmptyArray } from '../../helpers/arrays.helper';
import { TopOption } from '../../Models/TopOption';
import Loader from '../Common/Loader/Loader';
import StickySearchWrapper from '../Common/StickySearchWrapper/StickySearchWrapper';

export const DynamicOffersList = ({isPreviewMode = false }) => {
  const limit = productsLimit;
  const apiUrl = `${process.env.API_URL}/products`;
  const [products, setProducts] = useState({});
  const [isProductsLoaded, setIsProductsLoaded] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [paramsState, setParamsState] = useState(null);
  const [isQueryError, setIsQueryError] = useState(false);
  const [attribute, setAttribute] = useState({});
  const resultListId = 'results-list';

  if (hasWindow) {
    const deps = isPreviewMode ? [] : [useLocation()];

    useEffect(() => {
      setParamsState(getParamsStateFromUrlQuery());
    }, deps);
  }

  useEffect(() => {
    if (paramsState) {
      if (!attribute || !isNotEmptyArray(attribute.options)) {
        setAttribute(getDefaultAttribute(paramsState));
      } else  {
        updateTopOptionsCheckedState(attribute.options, paramsState);
      }

      loadProducts();
    }
  }, [paramsState]);

  function getDefaultAttribute() {
    const transformedAttribute = transformToAttribute(productAttribute, paramsState, TopOption)
    setDefaultCheckedState(transformedAttribute.options);
    return transformedAttribute;
  }

  function updateTopOptionsCheckedState(options, paramsState) {
    options.forEach(option => {
      if (!option || !option.key || !option.value) {
        option.checked = false;
      }

      option.checked = paramsState && isNotEmptyArray(paramsState[option.key])
        ? paramsState[option.key].includes(option.value) : false;

    })

    setDefaultCheckedState(options);
  }

  function setDefaultCheckedState(options) {
    const isSelectedOption = options.some(option => option.checked);
    if (!isSelectedOption) {
      options.forEach(option => {
        if (option.default) {
          option.checked = true;
        }
      })
    }
  }

  function getParamsStateFromUrlQuery() {
    const state = {
      query: QueryParamsHelper.getOne('query'),
    }

    const keys = QueryParamsHelper.getAllUniqueKeys();
    keys.forEach(key => {
      if (key !== 'query' && key !== 'page') {
        state[key] = QueryParamsHelper.getAll(key) || [];
      }
    })

    return state;
  }

  function getParams() {
    let params = { limit }

    if (paramsState) {
      const APIParamsFromState = QueryParamsHelper.transformParamsStateToAPIParams(paramsState)
      params = APIParamsFromState ? { ...APIParamsFromState, ...params } : params;
    }

    return params;
  }

  function loadProducts() {
    setIsLoader(true);
    setIsQueryError(false);
    getProducts(getParams())
      .then((res) => {
        setProducts(res.data);
        setIsProductsLoaded(true);
      })
      .catch(error => handleBadRequestError(error, onFieldError))
      .finally(() => {
        setIsLoader(false)
      });
  }

  async function getProducts(params) {
    return await axiosService.get(apiUrl, { params }).then((res) => res.data);
  }

  function getTopOptions() {
    if (attribute && isNotEmptyArray(attribute.options)) {
      return attribute.options || [];
    }
    return [];
  }

  function onFieldError(field) {
    if (field && field.property && field.property === 'query') {
      setIsQueryError(true);
    }
  }

  function isProducts() {
    return products && (isLessons() || isPackages() || isArtists());
  }

  function isLessons() {
    return products && products.lessons && isNotEmptyArray(products.lessons.data);
  }

  function isPackages() {
    return products && products.packages && isNotEmptyArray(products.packages.data);
  }

  function isArtists() {
    return products && products.artists && isNotEmptyArray(products.artists.data);
  }

  return (
    <div className={`container pb-5`}>
      <StickySearchWrapper>
        <Search
          isPreviewMode={isPreviewMode}
          className={`mb-2`}
          inputQuery={paramsState ? paramsState.query : ''}
          hasError={isQueryError}
          defaultSearchOption={searchOptions[0]}
          anchor={resultListId}
        />
      </StickySearchWrapper>

      <TopOptionsList
        topOptions={getTopOptions()}
        className={`mb-5 md-lg-8`}
        isPreviewMode={isPreviewMode}
      />

      <div className={`position-relative ${!isProductsLoaded ? 'vh-75' : ''}`} id={resultListId}>
        {isLoader && (<Loader hasBackground={true}/>)}
        {
          isProducts() && (
            <div className="row">
              <div className="col col-12">
                <ProductsSection
                  products={products ? products.lessons : null}
                  title={`Lessons`}
                  productPath={`/lessons`}
                  viewAllPath={`/lessons`}
                  productType={productTypes.lesson}
                  isLoader={isLoader}
                  isProductsLoaded={isProductsLoaded}
                  isPreviewMode={isPreviewMode}
                />

                <ProductsSection
                  products={products ? products.packages : null}
                  title={`Packages`}
                  productPath={`/packages`}
                  viewAllPath={`/packages`}
                  productType={productTypes.package}
                  isLoader={isLoader}
                  isProductsLoaded={isProductsLoaded}
                  isPreviewMode={isPreviewMode}
                />

                <ProductsSection
                  products={products ? products.artists : null}
                  title={`Artists`}
                  productPath={`/lessons`}
                  viewAllPath={`/bands`}
                  productType={productTypes.artist}
                  isLoader={isLoader}
                  isProductsLoaded={isProductsLoaded}
                  isFilter={true}
                  isPreviewMode={isPreviewMode}
                />
              </div>
            </div>
        )}
        {
          !isProducts() && isProductsLoaded && (
            <p className="border-warning border border-radius-1 mb-7 p-2 text-center">
              <span className={`uil uil-info-circle text-warning d-inline`}/>
              <span>Sorry, no results were found. Please, try another search term.</span>
            </p>
          )
        }
      </div>
    </div>
  );
}

DynamicOffersList.propTypes = {
  isPreviewMode: PropTypes.bool,
};

export default DynamicOffersList;
