import React from 'react';
import PropTypes from 'prop-types';
import ProductCard from '../../Common/ProductCard/ProductCard';
import { Link } from 'gatsby';
import * as _ from 'lodash';
import { productTypes } from '../../../constants/product-types.const';
import { isPositiveIntegerOrZero } from '../../../helpers/number.helper';
import './products-list.scss';
import { findMainTag } from '../../../helpers/tags.helper';
import { isNotEmptyArray } from '../../../helpers/arrays.helper';

const ProductsList = ({
   products,
   productPath,
   productType,
   className = '',
   isFilter = false,
   colClassName = ''
 }) => {

  function isProducts() {
    return products && isNotEmptyArray(products);
  }

  function getSingleProductPath(product) {
    if (product && isPositiveIntegerOrZero(product.id)) {
      if (isFilter && product.attributesKey) {
        return `${productPath}/?${product.attributesKey}=${product.id}`
      }

      if (product.title) {
        return `${productPath}/${product.id}-${_.kebabCase(product.title)}`
      }
    }

    return productPath;
  }

  function getTitle(product) {
    if (!product.title) {
      return '';
    }

    if (productType === productTypes.lesson && product.artist) {
      return `${product.title} by ${product.artist}`
    }

    return product.title;
  }

  function getTag(product) {
    if (product ) {
      return findMainTag(product.tags);
    }
  }

  return isProducts() && (
    <ul className={`${className} list-unstyled mb-0 d-flex flex-wrap products-list row`}>
      {
        products.map((product, i) => (
          <li key={i} className={`${colClassName} mb-3 mb-md-5 px-2`}>
            <Link to={getSingleProductPath(product)} className={'product-list-link'}>
              <ProductCard
                title={getTitle(product)}
                type={productType}
                thumbnail={product.thumbnail || ''}
                tag={getTag(product)}
              />
            </Link>
          </li>
        ))
      }
    </ul>
  );
}

ProductsList.propTypes = {
  products: PropTypes.array.isRequired,
  productType: PropTypes.string.isRequired,
  productPath: PropTypes.string.isRequired,
  isFilter: PropTypes.bool,
  className: PropTypes.string,
  colClassName: PropTypes.string,
};

export default ProductsList;

