import React,  { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { mediumBreakpoint } from '../../../../../constants/breakpoints.const';

export const SubNav = ({ subLinks }) => {
  const [isMediumScreen, setIsMediumScreen] = useState(false);

  function handleScreenWidthChange() {
    setIsMediumScreen(window.innerWidth < mediumBreakpoint);
  }

  useEffect(() => {
    handleScreenWidthChange();
    window.addEventListener('resize', handleScreenWidthChange);
    return () => {
      window.removeEventListener('resize', handleScreenWidthChange);
    };
  });

  return !isMediumScreen && (
    <nav className="sub-nav d-block" aria-label="main-navigation">
      <div className="container">
        <div className="row">
          <div className="col">
            <ul className={`list-unstyled sub-nav-list mb-0 py-1`}>
              {
                subLinks.map((subLink, i) => (
                  <li className="sub-nav-item" key={i}>
                    <Link className={`sub-nav-link`} to={subLink.path} activeClassName="active" partiallyActive={true}>
                      {subLink.name}
                    </Link>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

SubNav.propTypes = {
  subLinks: PropTypes.array.isRequired,
};

export default SubNav;
