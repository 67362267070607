import React from 'react';
import './product-card.scss'
import PropTypes from 'prop-types';
import PreviewCompatibleImage from '../../Common/PreviewCompatibleImage';
import { productTypes, productTypesArray } from '../../../constants/product-types.const';
import CustomBadge from '../../Common/CustomBadge/CustomBadge';
import { getArtistInitials } from '../../../helpers/artists.helper';
import { isValidTag } from '../../../helpers/tags.helper';
import lessonImg from '../../../img/guitar-rectangle.svg';
import packageImg from '../../../img/guitar.svg';
import { getHeroImageSmallResPath } from '../../../helpers/images.helper';

const ProductCard = ({title, type, thumbnail, className = '', tag}) => {

  function getProductTypeClass() {
    if (productTypesArray.includes(type)) {
      return `product-${type}-card`;
    }

    return '';
  }

  function getThumbnail() {
    switch (type) {
      case productTypes.lesson:
        return thumbnail || lessonImg;
      case productTypes.package:
        return getHeroImageSmallResPath(thumbnail, '_md') || packageImg;
      default:
        return thumbnail || '';
    }
  }

  function isInitialVisible() {
    return type === productTypes.artist;
  }

  return (
    <div className={`${className} product-card ${getProductTypeClass()}`}>
      <div className="thumbnail flex-shrink-0 mr-0 mr-sm-3 mr-md-4">
        <div className="thumbnail-container">
          <div className="thumbnail-wrapper">
            {
              !!getThumbnail() && (
                <PreviewCompatibleImage
                  imageInfo={{
                    image: getThumbnail(),
                    alt: title,
                    className: ''
                  }}
                />
              )
            }
            {
              !thumbnail && isInitialVisible() && (
                <p className={`alt-text p-2 text-center mb-0`}>{getArtistInitials(title)}</p>
              )
            }
          </div>
        </div>
      </div>
      {isValidTag(tag) && (<CustomBadge title={tag.title} color={tag.color} className={`product-card-badge`}/>)}
      <h5 className="product-card-title small font-weight-bold py-2 mb-0">{title}</h5>
    </div>
  );
}

ProductCard.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  tag: PropTypes.object,
  className: PropTypes.string,
};

export default ProductCard;

