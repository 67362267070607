import { Option } from './Option';

export class TopOption extends Option {
  backgroundImage = '';
  icon = '';
  default = false;

  constructor(props) {
    super(props);
    this.backgroundImage = props.backgroundImage || this.backgroundImage;
    this.icon = props.icon || this.icon;
    this.default = props.default || this.default;
  }
}
