export class Option {
  key = ''
  value = '';
  title = '';
  count = 0;
  checked = false;

  constructor(props) {
    this.key = props.key || this.key;
    this.value = String(props.value) || this.value;
    this.title = props.title || this.title;
    this.count = props.count || this.count;
    this.checked = props.checked || this.checked;
  }
}

