import { isNotEmptyArray } from './arrays.helper';
import { each } from 'underscore';
import { Option } from '../Models/Option';
import { isPositiveIntegerOrZero } from './number.helper';

export function transformAPIDataToAttributes(data, paramsState, plainToClass = Option) {
  if (isNotEmptyArray(data)) {
    return data.map(attribute => transformToAttribute(attribute, paramsState, plainToClass))
  }

  return [];
}

export function transformToAttribute(attribute, paramsState, plainToClass = Option) {
  if (!attribute) {
    return;
  }

  return {
    title: attribute.title || '',
    isTopLevelFilters: attribute.topLevelFilters || false,
    options: transformToOptions(attribute.options, paramsState, plainToClass) || []
  }
}

export function transformToOptions(options, paramsState, plainToClass = Option) {
  if (isNotEmptyArray(options)) {
    return options.map(option => transformToOption(option, paramsState, plainToClass));
  }
  return [];
}

export function transformToOption(option, paramsState, plainToClass = Option) {
  const { key, value, } = option;
  const optionData = { ...option }

  if (key) {
    optionData.checked = paramsState && isNotEmptyArray(paramsState[key])
      ? paramsState[key].includes(String(value))
      : false;
  }

  optionData.value = String(optionData.value);

  return new plainToClass(optionData);
}

export function isValidOption(option) {
  return option
    && option.hasOwnProperty('key') && typeof option.key === 'string'
    && option.hasOwnProperty('value') && typeof option.value === 'string'
    && option.hasOwnProperty('title') && typeof option.title === 'string'
    && option.hasOwnProperty('count') && isPositiveIntegerOrZero(option.count);
}

export function getSelectedOptions(paramsState, attributes) {
  const selectedOptions = [];

  each(paramsState, (value, key) => {
    if (value && typeof value === 'string') {
      selectedOptions.push(new Option({ key, value: value, title: value }));
    }

    if (isNotEmptyArray(value) && isNotEmptyArray(attributes)) {
      const options = getSelectedOptionsByKey(value, attributes, key) || [];
      selectedOptions.push(...options)
    }
  });

  return selectedOptions;
}

export function getSelectedOptionsByKey(selectedValues, attributes, key) {
  if (!isNotEmptyArray(selectedValues) || !isNotEmptyArray(attributes)) {
    return [];
  }

  const allOptions = findAllOptionsByKey(key, attributes);
  return allOptions.filter(option => selectedValues.includes(String(option.value)));
}

export function findAllOptionsByKey(key, attributes) {
  const options = getAllOptions(attributes);
  return options.filter(option => option.key === key);
}

export function getAllOptions(attributes) {
  const options = [];

  if (!isNotEmptyArray(attributes)) {
    return [];
  }

  attributes.forEach(attribute => {
    if (attribute && isNotEmptyArray(attribute.options)) {
      attribute.options.forEach(option => {
        options.push(option);
      })
    }
  })

  return options;
}
