import { isNumber } from 'underscore';

export function isPositiveIntegers(...values) {
  return values.every(item => isPositiveInteger(item));
}

export function isPositiveInteger(value) {
  return isInteger(value) && value > 0;
}

export function isPositiveIntegerOrZero(value) {
  return isInteger(value) && value >= 0;
}

export function isInteger(value) {
  return isNumber(value) && Number.isInteger(value);
}
