import React from 'react';
import './custom-badge.scss'
import PropTypes from 'prop-types';

const CustomBadge = ({title, color = '', className = ''}) => {
  const defaultBackgroundColor = '#ff921a';
  const style = {
    backgroundColor: color || defaultBackgroundColor,
  };

  return (
    <span style={style} className={`${className} custom-badge`}>
      <span className={`text-uppercase`}>{title}</span>
    </span>
  );
}

CustomBadge.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
};

export default CustomBadge;

