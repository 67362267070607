import React from 'react';
import PropTypes from 'prop-types';
import './top-option-component.scss';
import { getOne } from '../../../helpers/query-params.helper';
import hasWindow from '../../../constants/has-window.const';
import { isValidOption } from '../../../helpers/attributes.helper';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

const TopOptionComponent = ({option, isPreviewMode = false }) => {
  let style = {
    backgroundImage: `url(${option.backgroundImage})`
  }

  const location = isPreviewMode ? null : useLocation();

  function toggleFilter(state) {
    if (!hasWindow || !isValidOption(option) || isPreviewMode) {
      return;
    }

    option.checked = state;

    let link = location ? location.pathname : 'specials';
    if (option.key && option.value) {
      link = `${link}?${option.key}=${option.value}`
    }

    const separator = link.includes('?') ? '&' : '?'
    const query = getOne('query');

    link = query ? `${link}${separator}query=${query}` : link;

    return navigate(link);
  }

  return (
    isValidOption(option) && (
      <label className={`m-0 p-0 w-100 h-100`}>
        <input
          type="radio"
          name={`top-filter`}
          className={`d-none`}
          checked={option.checked}
          onChange={(event) => toggleFilter(event.target.checked)}
        />
        <div
          className={`top-filter-radio h-100 p-2`}
          style={style}
        >
          <div className={`h-100 top-filter-content d-flex flex-column align-items-center justify-content-center`}>
            <div className={`mb-2 d-none d-lg-flex align-items-center flex-grow-1`}>
              <img src={option.icon} alt={option.title} className={`filter-icon`}/>
            </div>

            <span className={`mt-lg-auto`}>{ option.title || option.value }</span>
          </div>
        </div>
      </label>
    )
  )
};

TopOptionComponent.propTypes = {
  option: PropTypes.object.isRequired,
  isPreviewMode: PropTypes.bool,
};

export default TopOptionComponent;
