export function getArtistInitials(value) {
  const separator = /\W|_/g;
  const namesInitialsArray = value
    .split(separator)
    .filter(item => item.length > 0)
    .slice(0, 2)
    .map(item => item[0]);

  return namesInitialsArray.join('');
}
